<template>
  <div>
    <load-profile></load-profile>
    <!-- Table Container Card -->
    <!-- form -->
    <validation-observer ref="editForm" #default="{ invalid }">
      <n-form-confirmation
        key="editForm"
        @submit="submit"
        :form="$refs.editForm"
        :disabled="invalid"
      >
        <b-row>
          <b-col cols="12">
            <b-card>
              <b-col cols="4">
                <div>Download Sample Here:</div>
                <a
                  href="https://file.io/FJ32joDfsEs2"
                  target="_blank"
                  variant="info"
                  >Sample.csv</a
                >
              </b-col>
              <b-col cols="4">
                <div class="mt-3">Upload file:</div>
                <b-form-file
                  v-model="file"
                  accept=".csv, .xlsx"
                  required
                ></b-form-file>
              </b-col>
              <b-col cols="12" class="text-right">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  class="mt-1 mr-1"
                  @click="checkData()"
                >
                  Check Data
                </b-button>

                <n-button-loading
                  type="submit"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-1 mr-1"
                  :loading="loading"
                  :disabled="invalid"
                >
                  Create Pre Order
                </n-button-loading>
              </b-col>
            </b-card>
          </b-col>
        </b-row>
      </n-form-confirmation>
    </validation-observer>
    <b-card no-body class="mb-0">
      <div class="m-0-5"></div>
      <div>
        <n-table
          :fields="columns"
          :items="items"
          :busy="loading"
          :current-page="params.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
        >
        </n-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BFormRadioGroup,
  BTab,
  BButton,
  BTabs,
  BFormTextarea,
  BCardHeader,
  BCardBody,
  BFormFile,
} from 'bootstrap-vue'
import Repository from '@/repositories/RepositoryFactory'
import Ripple from 'vue-ripple-directive'
import NButtonLoading from '@/components/NButtonLoading'
import NFormConfirmation from '@/components/NFormConfirmation'
import tableFields from './tableField'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import NPagination from '@/components/NPagination'
import NInput from '@/components/NInput'
import NSearchContainer from '@/components/NSearchContainer'
import NSearchInput from '@/components/NSearchInput'
import NTable from '@/components/NTable'

const ManualPreOrderRepository = Repository.get('adminPreOrder')

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BFormFile,
    BLink,
    BFormRadioGroup,
    NButtonLoading,
    NFormConfirmation,
    NTable,
    NPagination,
    NInput,
    BTab,
    BTabs,
    BFormTextarea,
    BButton,
    BCardHeader,
    BCardBody,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        zoneId: '',
        gameId: '',
        packageId: '',
      },

      items: [],
      file: null,
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
        name: this.$route.query.name || null,
        isEnable: this.$route.query.isEnable || null,
        limit: this.$store.state.pagination.perPage || 10,
        gameId: this.$route.query.game_id || '',
        tran_id: this.$route.query.tran_id || '',
        sortPay: null,
        sortType: null,
        type: null,
      },
      visible: false,
      collapseItemID: '',
      openOnHover: this.$parent.hover,
    }
  },
  mounted() {},
  methods: {
    checkData() {
      if (this.file) {
        const payload = new FormData()
        payload.append('file', this.file)
        this.loading = true
        ManualPreOrderRepository.upload(payload)
          .then((response) => {
            this.items = response.data.data.map((item) => ({
              ...item,
              price: parseFloat(item.price),
            }))
            console.log(this.items, 'data')

            this.$toast.success(
              `${response.data.message} Valid Data: ${response.data.totalCanTopup} Invalid Data: ${response.data.totalCannotTopup}`
            )
          })
          .catch((error) => {
            this.$toast.error(error.message)
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    submit() {
      this.$refs.editForm.validate().then((success) => {
        if (success) {
          this.loading = true
          ManualPreOrderRepository.confirm({ data: this.items })
            .then((response) => {
              this.$toast.success(`${response.data.message}`)
            })
            .catch((error) => {
              this.$toast.error(error.message)
            })
            .finally(() => {
              this.loading = false
              this.items = []
              this.file = null
            })
        }
      })
    },

    back() {
      this.$router.back()
    },
  },
  setup() {
    const columns = [...tableFields]

    return { columns }
  },
}
</script>
