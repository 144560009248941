export default [
  {
    key: 'increasement',
    sortable: false,
    sortField: '',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'gameId',
    sortable: false,
    sortField: '',
    label: 'Game ID',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'serverId',
    sortable: false,
    sortField: '',
    label: 'Server ID',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'name',
    sortable: false,
    sortField: '',
    label: 'Name',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'diamond',
    sortable: false,
    sortField: '',
    label: 'field.amount_diamond',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'price',
    sortable: false,
    sortField: '',
    label: 'Price',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'package',
    sortable: false,
    sortField: '',
    label: 'Package',
    stickyColumn: true,
    variant: 'light',
  },
]
